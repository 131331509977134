import { BiCctv, BiTargetLock } from "react-icons/bi"
import { TiWeatherCloudy } from "react-icons/ti";
import { TbRouter } from "react-icons/tb";
import DeviceStore from "../../../Store/DeviceStorage";
import { Popover } from "antd";
import { Device, DeviceType } from "../../../API";
import { StorageImage } from '@aws-amplify/ui-react-storage';

type Props = {
    device: Device
}

function DeviceIcon(props: Readonly<Props>) {

    const device = props.device;
    const xPosition = device.xLoc;
    const yPosition = device.yLoc;
    const type = device.type;
    const id = device.id;
    const name = device.name;
    const prevImg = device.prevUrl;

    const currendDevice = DeviceStore((state) => state.currentDevice)
    const isActive = currendDevice?.id === id

    const content = (
        <div>
            {prevImg &&
                <StorageImage alt="cat" imgKey={prevImg} height={200} accessLevel={"guest"} />
            }
        </div>
    );

    return (
        <>
            {(type === DeviceType.TARGET) &&
                <Popover content={content} title={name}>
                    <div style={{ position: 'absolute', left: xPosition, top: yPosition }}>
                        <div className={`icon shadow-4 ${isActive ? 'icons active ' : 'icons'}`}>
                            {type === DeviceType.TARGET &&
                                <BiTargetLock style={{ color: 'white', cursor: 'pointer' }} size="20px" />
                            }
                        </div>
                    </div>
                </Popover>

            }{(type === DeviceType.AICAMERA) &&
                <Popover content={content} title={name}>
                    <div style={{ position: 'absolute', left: xPosition, top: yPosition }}>
                        <div className={`icon shadow-4 ${isActive ? 'icons active ' : 'icons'}`}>
                            {type === DeviceType.AICAMERA &&
                                <BiCctv style={{ color: 'white', cursor: 'pointer' }} size="20px" />
                            }
                        </div>
                    </div>
                </Popover>

            }
            {(type === DeviceType.AICAMERA) &&
                <Popover content={content} title={name}>
                    <div style={{ position: 'absolute', left: xPosition, top: yPosition }}>
                        <div className={`icon shadow-4 ${isActive ? 'icons active ' : 'icons'}`}>
                            {type === DeviceType.AICAMERA &&
                                <BiCctv style={{ color: 'white', cursor: 'pointer' }} size="20px" />
                            }
                        </div>
                    </div>
                </Popover>

            }
            {(type === DeviceType.WEATHERSTATION) &&
                <Popover content={content} title={name}>
                    <div style={{ position: 'absolute', left: xPosition, top: yPosition }}>
                        <div className={`icon shadow-4 ${isActive ? 'icons active ' : 'icons'}`}>
                            {type === DeviceType.WEATHERSTATION &&
                                <TiWeatherCloudy style={{ color: 'white', cursor: 'pointer' }} size="20px" />
                            }
                        </div>
                    </div>
                </Popover>

            }
            {type !== DeviceType.TARGET && type !== DeviceType.AICAMERA && type !== DeviceType.WEATHERSTATION &&
                <div style={{ position: 'absolute', left: xPosition, top: yPosition }}>
                    <div className={`icon shadow-4 ${isActive ? 'icons active ' : 'icons'}`}>
                        {type === DeviceType.CAMERA &&
                            <BiCctv style={{ color: 'white' }} size="20px" />
                        }
                        {type === DeviceType.ROUTER &&
                            <TbRouter style={{ color: 'white' }} size="20px" />
                        }
                    </div>
                </div>
            }
        </>
    )
}

export default DeviceIcon