import { Select, Table } from "antd"
import './DataManagerPage.css'
import UserStore from "../../../Store/UserStore"
import { getLocations } from "../../../Service/Locations/LocationsService"
import { useState } from "react"
import { getDeviceDataByDeviceID, getDevices } from "../../../Service/Devices/DevicesService"
import { DeviceData, DeviceType } from "../../../API"

function DataManagerPage() {

    const [locations, setLocations] = useState([])
    const [devices, setDevices] = useState([])
    const [location, setLocation] = useState(null)
    const [device, setDevice] = useState(null)

    const clients = UserStore((state) => state.clients).map((client) => {
        return {
            label: client.name,
            value: client.id
        }
    })

    const clientHandleChange = (value: any) => {
        setDevice(null)
        setLocation(null)
        getLocations(value).then((locations) => {
            setLocations(locations.map((location) => {
                return {
                    label: location.name,
                    value: location.id
                }
            }))
        })
    }

    const locationHandleChange = (value: any) => {
        setLocation(value)
        setDevice(null)
        getDevices(value).then((devices) => {
            setDevices(devices.filter((device) => device.type === DeviceType.CAMERA).map((device) => {
                return {
                    label: device.name,
                    value: device.id
                }
            }))
        })
    }

    const deviceHandleChange = (value: any) => {
        setDevice(value)
        getDeviceDataByDeviceID(value).then((data) => {
            setdeviceData(data)
        })
    }

    const columns = [
        {
            title: 'Time',
            dataIndex: 'time',
            key: 'time',
        },
        {
            title: 'FileName',
            dataIndex: 'FileName',
            key: 'FileName',
        },
        {
            title: 'DateTimeOriginal',
            dataIndex: 'DateTimeOriginal',
            key: 'DateTimeOriginal',
        },
        {
            title: 'dxMm',
            dataIndex: 'dxMm',
            key: 'dxMm',
        },
        {
            title: 'dyMm',
            dataIndex: 'dyMm',
            key: 'dyMm',
        },
    ];

    const [deviceData, setdeviceData] = useState<DeviceData[]>([])


    return (
        <div className="App">
            <h1>Data Manager</h1>
            <div className="dataManager">
                <div className="dataManagerSelect">
                    <h4>Klient</h4>
                    <Select className='LocationDropDown'
                        onChange={clientHandleChange}
                        options={clients}
                    />
                </div>
                <div className="dataManagerSelect">
                    <h4>Lokalizacja</h4>
                    <Select
                        value={location}
                        className='LocationDropDown'
                        onChange={locationHandleChange}
                        options={locations}
                    />
                </div>
                <div className="dataManagerSelect">
                    <h4>Punkt</h4>
                    <Select
                        value={device}
                        className='LocationDropDown'
                        onChange={deviceHandleChange}
                        options={devices}
                    />
                </div>
            </div>
            <div>
                <Table columns={columns} dataSource={deviceData} />
            </div>
        </div>
    )
}

export default DataManagerPage